@import url('./font.css');

html,
body {
    margin: 0px;
    padding: 0px;
    height: 100%;
    background-color: rgba(245, 245, 235, 1);
    display: block;
    width: 100%;
    height: 100%;
    font-family: 'Open Sans', sans-serif;
    font-weight: 300;
}

#root {
    width: 100%;
    height: 100%;
}

@media (max-width: 600px) {
    h1,
    h2 {
        font-size: 8vw !important;
    }
}

h1,
h2 {
    font-size: 46px;
    font-weight: 300;
    color: #181818;
    transition: font-size 1s ease;
    margin-top: 5px;
    margin-bottom: 0px;
}

h2 {
    font-size: 26px;
}

.container {
    text-align: center;
    padding: 10%;
    padding-top: 2%;
    width: 80%;
}

.sections {
    padding-bottom: 10px;
}

hr {
    margin-top: 0px;
    margin-bottom: 10px;
    border-color: #C0C0C0;
    border: solid #ddd;
    border-width: 1px 0 0;
    clear: both;
    margin: 10px 0 10px 0px;
    height: 0;
}